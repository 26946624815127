import { Button, HStack, Skeleton, Stack } from "@chakra-ui/react"
import React from "react"

interface ProductCardSkeletonProps {
  quantity?: number
}

export const ProductCardSkeleton = (
  props: ProductCardSkeletonProps = { quantity: 1 },
) => {
  return Array.from(Array(props.quantity).keys()).map((_, index) => (
    <Stack
      width={{ base: "100%", md: "18rem" }}
      spacing="1rem"
      border="1px solid #ADBDB9"
      borderRadius="1.5rem"
      position="relative"
      zIndex={10}
      key={index}
    >
      <Stack
        background="#F2F4F3"
        zIndex={11}
        py="1.5rem"
        borderRadius="1.5rem"
        spacing="1rem"
        alignItems="center"
        justifyContent="center"
      >
        <Skeleton height="250px" width="250px" borderRadius="1rem" />

        <Stack alignItems="center" justifyContent="center">
          <Skeleton height="1.5rem" width="8rem" />
          <Skeleton height="2rem" width="16rem" />
        </Stack>

        <HStack alignItems="center" justifyContent="space-around" width="100%">
          <Skeleton height="3rem" width="3rem" borderRadius="50%" />
          <Skeleton height="3rem" width="3rem" borderRadius="50%" />
          <Skeleton height="3rem" width="3rem" borderRadius="50%" />
        </HStack>

        <Button color="#fff" borderRadius="12.5rem" opacity={1}>
          <Skeleton height="1.5rem" width="12rem" />
        </Button>
      </Stack>
    </Stack>
  ))
}
