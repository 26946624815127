export const OursLines = [
  {
    id: 1,
    name: "Natural Whey",
    category_id: "11",
    image:
      "https://admin.verdecampo.com.br/wp-content/uploads/2023/03/7898205925401-766x754.png",
    color: "#FFB600",
    claims: ['VC_Claims_OrigemNatural.svg', 'VC_Claims_Proteinas.svg', 'VC_Claims_ZeroLactose.svg', 'VC_Claims_Colageno.svg'],
    description: "Família de produtos Verde Campo pensadas para a performance corporal, seja no pré ou pós treino, ou em uma rotina exigente. Natural Whey, com ingredientes de origem natural e sabores surpreendentes, oferecem potência para suas atividades que exigem mais do corpo."
  },
  {
    id: 2,
    name: "LACFREE",
    category_id: "6",
    image:
      "https://admin.verdecampo.com.br/wp-content/uploads/2018/11/18682-MOCKUP-COCA-COLA-LACFREE-IOGURTE-MORANGO-140G.png",
    color: "#FF8674",
    claims: ['VC_Claims_OrigemNatural.svg', 'VC_Claims_Stevia.svg', 'VC_Claims_ZeroLactose.svg', 'VC_Claims_ZeroAdicaoAcucares.svg',],
    description: "Família de produtos Verde Campo que têm como essência oferecer produtos sem lactose, sem açúcar e sem gordura, para momentos do dia a dia em que menos é mais, e que uma alimentação mais leve, pura e prática faz toda a diferença."
  },
  {
    id: 3,
    name: "Corpo e Mente",
    category_id: "41",
    image:
      "https://admin.verdecampo.com.br/wp-content/uploads/2020/08/19557-MOCKUP-COCA-COLA-IOGURTE-TRADICONAL-BMM-500G_AF05-766x754.png",
    color: "#00BF6F",
    claims: ['VC_Claims_OrigemNatural.svg', 'VC_Claims_Gastro.svg', 'VC_Claims_Corante.svg'],
    description: "Família de produtos Verde Campo pensada especialmente para atender as demandas específicas de sistemas do corpo que nos ajudam a viver melhor e com mais bem estar. Desde soluções para o intestino até para a concentração, pensamos em órgãos e corpos que exigem maior especificidade e cuidado alimentar, para que seu corpo funcione em seu melhor desempenho."
  },
  {
    id: 4,
    name: "Kids",
    category_id: "40",
    image:
      "https://admin.verdecampo.com.br/wp-content/uploads/2020/08/24219-MOCKUP-COCA-COLA-LINHA-KIDS-IOGURTE-KIDS-BANANA-E-MACA-170g_A05.png",
    color: "#F8CFA9",
    claims: ['VC_Claims_OrigemNatural.svg', 'VC_Claims_Corante.svg', 'VC_Claims_Leite.svg', 'VC_Claims_BoasPraticas.svg'],
    description: "Família de produtos Verde Campo elaborada especialmente para apoiar ao máximo o bom desenvolvendo da criança, com a naturalidade e a proteína láctea de sempre. Nossa linha Kids possui tudo o que uma criança precisa para se desenvolver nutricionalmente feliz e saudável."
  },
  {
    id: 5,
    name: "Essencial",
    category_id: "64",
    image:
      "https://admin.verdecampo.com.br/wp-content/uploads/2018/11/7898205925449-766x754.png",
    color: "#00754A",
    claims: ['VC_Claims_OrigemNatural.svg', 'VC_Claims_Leite.svg', 'VC_Claims_Receitas.svg'],
    description: "Verde Campo essencial é a família de produtos clássicos de todos os dias, feitos com ingredientes de origem natural, proteína láctea e experiência única. São sabores que vão bem em toda hora do dia, com cremosidade e um gostinho de prazer que só a Verde Campo tem. Ingredientes que fazem parte de receitas clássicas e combinam o melhor do leite com o que faz bem para você e te dá positividade e disposição para se cuidar."
  },
  {
    id: 6,
    name: "Sentidos",
    category_id: "75",
    image:
      "https://admin.verdecampo.com.br/wp-content/uploads/2024/10/SOBREMESA-BananaCanela.png",
    color: "#f2c900",
    claims: ['VC_Claims_OrigemNatural.svg', 'VC_Claims_Leite.svg', 'VC_Claims_Receitas.svg'],
    description: "Produtos que se destacam por serem sensorialmente empolgantes sem deixar a naturalidade e a proteína láctea de lado. São uma alternativa para os momentos de indulgência, apresentando soluções deliciosas e equilibradas."
  },
]
